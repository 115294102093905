/**vinod code**/
.red-error {
  color: red;
}

.custom-info-window {
  min-height: 72px;

  p.text-sm {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  &::after {
    background: #fff;
    -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    content: "";
    height: 12px;
    left: 0;
    right: 0px;
    position: absolute;
    top: auto;
    bottom: -10px;
    margin: auto;
    width: 25px;
  }
}

.my-location-icon {
  position: absolute;
  bottom: 108px;
  /* Adjust the top position as needed */
  right: 18px;
  /* Adjust the right position as needed */
  cursor: pointer;
  z-index: 1000;
  /* Ensure it appears above the map */
  background: #fff;
  margin: 5px;
  width: 18px;
  height: 18px;
  background-image: url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png);
  background-size: 180px 18px;
  background-position: -144px 0px;
  background-repeat: no-repeat;
}

.header-address-box {
  max-height: 500px;
  overflow-x: hidden;
  .border.border-gray-300.border-solid.flex {
    padding: 10px !important;
    height: auto !important;
    button.flex.items-center {
      display: none;
    }
  }
}
.max-h-48vh {
  max-height: 48vh;
  overflow: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.readonly input {
  pointer-events: none !important;
  // cursor:default!important;
}

.Mui-error {
  .MuiOutlinedInput-notchedOutline {
    border-color: #666666 !important;
  }
}

.otp-verification-form {
  .MuiFormControl-root {
    margin-bottom: 10px !important;

    .MuiTextField-root {
      margin-bottom: 0 !important;
    }
  }
}

.pointer-none {
  pointer-events: none;
}

.opacity-down {
  pointer-events: none;
  opacity: 0.6;
}

.rabbit-mb-10 {
  margin-bottom: 10px;
}

.height-full-header-footer {
  min-height: calc(100vh - 500px);
}

.datepicker-parent .MuiFormControl-root {
  width: 100%;
}

.MuiFormControl-root {
  &[readonly] {
    .MuiInputBase-input {
      cursor: default;
    }
  }

  .MuiInputBase-input.MuiOutlinedInput-input {
    min-height: 40px;
    line-height: 40px;
    padding: 0.5rem 0.75rem !important;
    background: transparent !important;
    font-family: "Nunito-Medium" !important;
  }

  .mobile-input {
    .MuiInputLabel-root {
      background: var(--white_A700);
    }

    .MuiInputBase-input.MuiOutlinedInput-input {
      padding-left: 60px !important;
    }
  }

  .contry-code {
    position: absolute;
    left: 3px;
    top: 15px;
    height: auto;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.is-value-exist {
  .MuiFormLabel-root.MuiInputLabel-root {
    transform: translate(14px, -9px) scale(0.75) !important;
    background-color: transparent !important;
    // background-color: var(--white_A700) !important;
    max-width: calc(133% - 32px);
  }
}

.my-order-tabs {
  .MuiTabs-flexContainer {
    gap: 6px;

    @media screen and (max-width: "990px") {
      flex-direction: row;
    }
  }

  .MuiButtonBase-root.MuiTab-root {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-transform: none;
    font-family: "Nunito-Medium";
    font-size: 16px;
    color: var(--black);
    padding: 14px 12px;

    &.Mui-selected {
      background: var(--white_A700);
      color: var(--black);
      max-width: 100%;
    }
  }

  .MuiTabs-indicator {
    display: none !important;
  }
}

.before-pink-round::before {
  content: "";
  width: 360px;
  height: 360px;
  background: var(--gray_50_02);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: -100px;
  top: -100px;
  z-index: -1;
}

.before-pink-round.before-pink-round-modal::before {
  z-index: 0;
}

.MuiTypography-body1 {
  font-size: 14px !important;
  font-family: "Nunito-Medium" !important;
}

// checkbox
.Mui-checked {
  color: #bd0043 !important;
}
.content-area {
  input[type="checkbox"]{
    margin: 0px !important;
  }
}

// table
.MuiTableRow-head {
  background: var(--gray_50_02);

  .MuiTableCell-head {
    font-family: "Nunito-Medium" !important;
    font-size: 14px !important;
    color: var(--black) !important;
    padding: 11px 20px !important;
    border-bottom: none !important;
  }
}

.MuiTableBody-root {
  .MuiTableRow-root {
    .MuiTableCell-root {
      font-family: "Nunito-Medium" !important;
      font-size: 14px !important;
      color: var(--black) !important;
    }
  }
}

.step-icon {
  background: var(--white_A700);

  img {
    filter: grayscale(1) contrast(0.5) opacity(1);
  }

  &.active {
    background: var(--red_100);

    img {
      filter: inherit;
    }
  }
}

.step-line {
  height: calc(100% + 36px);
  border-color: var(--gray_300);

  &.success {
    border-color: var(--gray_900_01);
  }
}

// detail-box-height
.detail-box-height {
  //height: calc(100vh - 300px);
  max-height: 250px;
  overflow-x: hide;
  overflow: auto;
}

.defaultmoney input[type="radio"] {
  position: absolute !important;
}

.defaultmoney input[type="radio"]:checked+label {
  background-color: #ffeced;
  border: 1px solid #ffeced !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.payment-secure-box {
  width: calc(100% + 48px);

  @media screen and (max-width: "550px") {
    width: calc(100% + 32px);
  }
}

.edit-delete-menu {
  padding: 2px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.toggle-content {
  display: block;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 900ms ease, height 900ms ease;

  &.show {
    display: block;
    opacity: 1;
    height: auto;
  }
}

.search-map-input {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  width: calc(100% - 20px);
  z-index: 9;
  margin: auto;
  border-radius: 4px;

  .pac-target-input {
    width: 100%;
    height: 100%;
    padding: 8px 40px 12px 40px;
    font-family: "Nunito-Medium" !important;
  }
}

.search-map-input+div {
  @media (max-width: 991px) {
    max-height: 300px !important;
  }
}

.MuiButtonBase-root.MuiMenuItem-root {
  font-family: "Nunito-Medium" !important;

  &:hover {
    background: var(--gray_50_02) !important;
    color: var(--pink_800) !important;
  }
}

#profilemenu-button {
  &[aria-expanded="true"] {
    .hidden.bg-pink-800 {
      display: block !important;
    }
  }
}

#profilemenu-menu {
  .MuiPaper-root.MuiMenu-paper {
    border-radius: 0px !important;
    top: 40px !important;
    width: 170px;

    .MuiList-root.MuiList-padding {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: var(--gray_300);
      }
    }
  }
}

// progress-line
.toggle-content {
  .flex {
    .title {
      @media screen and (max-width: "550px") {
        width: calc(100% - 40px);
      }
    }

    .progress-line {
      position: absolute;
      left: 11px;
      top: -97%;
      height: calc(100% + 5px);
      width: 2px;
      transition: width 1s ease;

      &.success {
        background: var(--teal_400);
      }

      &.error {
        background: var(--pink_800);
      }

      &.gray {
        background: var(--gray_300);
        // height: calc(100% + -15px);
        // top: -90px;
      }
    }

    &:first-child {
      .progress-line {
        display: none;
      }
    }
  }
}

[data-testid="CalendarIcon"] {
  width: 24px;
  height: 24px;
  background: url("../../public/images/Calendar.svg") no-repeat center center;
  background-size: auto;

  path {
    display: none;
  }
}

.bg-right-top-center {
  background-position: left top, right center;

  @media screen and (max-width: "550px") {
    background-position: left top, left center;
  }
}

.image-slider .alice-carousel {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @media screen and (max-width: "550px") {
    gap: 0;
  }

  >div {
    width: auto;

    &:first-child {
      order: 2;
      width: 488px;

      @media screen and (max-width: "550px") {
        width: 180px;
      }

      overflow: hidden;
    }

    &.alice-carousel__prev-btn {
      order: 1;
      padding: 0;
      display: block;

      .alice-carousel__prev-btn-item {
        padding: 0;
        display: block;

        span {
          &::after {
            content: url("../../public/images/arrow-right.svg") !important;
            height: 32px;
            width: 32px;
            display: block;
          }
        }
      }
    }

    &.alice-carousel__next-btn {
      order: 3;
      padding: 0;
      display: block;

      .alice-carousel__next-btn-item {
        padding: 0;
        display: block;

        span {
          &::after {
            content: url("../../public/images/arrow-left.svg") !important;
            height: 32px;
            width: 32px;
            display: block;
          }
        }
      }
    }
  }
}

.pac-logo {
  &::after {
    display: none;
  }
}

.min-height-question {
  text-align: center;
  min-height: calc(100vh - 145px);
  max-height: calc(100vh - 145px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  margin-bottom: 75px;
}

.hover-box {
  .hover-line {
    width: auto;
    min-width: max-content;
    transition: all 0.5s ease-in-out;
    transform: rotate(-45deg) translate(-40px, 18px) scaleX(0.5);
    background: linear-gradient(235deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%,
        rgba(0, 212, 255, 0) 100%);
    box-shadow: 0px 0px 30px 0px #0000000d;
    justify-content: flex-end;
    position: relative;
    z-index: 99;
    gap: 10px;

    .text {
      visibility: visible;
      opacity: 0;
      transform: rotate(0);
    }

    img {
      transform: scaleX(-2) rotate(45deg) translate(5px, -6px);
    }
  }

  @media screen and (min-width: 991px) {
    &:hover {
      .hover-line {
        transition: all 0.5s ease-in-out;
        transform: rotate(-45deg) translate(130px, 18px) scaleX(1);
        background: linear-gradient(235deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 100%,
            rgba(255, 255, 255, 1) 100%);

        .text {
          visibility: visible;
          opacity: 1;
          transition: all 0.5s ease-in-out;
        }

        img {
          transform: scaleX(1) rotate(45deg) translate(0px, 0px);
        }
      }
    }
  }
}

.indicator-hide {
  .alice-carousel {
    &__prev-btn {
      position: absolute;
      top: -153px;
      right: 7px;
      width: auto !important;
      opacity: 0;

      @media screen and (max-width: 550px) {
        top: -83px;
        left: 7px;
        right: unset;
      }
    }

    &__next-btn {
      position: absolute;
      top: -153px;
      right: 65px;
      width: auto !important;
      opacity: 0;

      @media screen and (max-width: 550px) {
        top: -83px;
        left: 65px;
        right: unset;
      }
    }
  }

  &.blog-indicator {
    .alice-carousel {
      &__prev-btn {
        top: -73px;

        @media screen and (max-width: 550px) {
          top: -83px;
        }
      }

      &__next-btn {
        top: -73px;

        @media screen and (max-width: 550px) {
          top: -83px;
        }
      }
    }
  }

  .inline-block {
    display: none;
  }
}

.static-page {
  padding-bottom: 30px;

  ul,
  ol {
    padding-left: 24px;
    margin-bottom: 16px;

    li {
      list-style: disc;
    }
  }

  h1 {
    font-size: 2em;
    margin-bottom: 16px;
  }

  h2 {
    font-size: 1.5em;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 1.17em;
    margin-bottom: 16px;
  }

  h4 {
    font-size: 1em;
    margin-bottom: 16px;
  }

  h5 {
    font-size: 0.83em;
    margin-bottom: 16px;
  }

  h6 {
    font-size: 0.67em;
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 16px;
  }
}

.product-slick {
  .slick-track {
    width: 100% !important;

    @media screen and (max-width: 550px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 12px;
    }

    .slick-slide {
      width: 100% !important;

      @media screen and (max-width: 550px) {
        width: 80px !important;
      }
    }
  }
}

.product-main-img {
  img {
    border-radius: 8px;
    overflow: hidden;
  }

  .js-image-zoom__zoomed-area {
    left: 100% !important;
    width: 440px !important;
  }

  .js-image-zoom__zoomed-image {
    left: 100% !important;
    width: 440px !important;
    background-position-x: center !important;
    z-index: 9;
  }
}

.profilemenu-button-line {
  width: calc(100% + 24px);
}

.accordion-ui {
  .MuiCollapse-root {
    .MuiAccordionDetails-root {
      @media screen and (max-width: 550px) {
        padding: 0px 15px 54px 70px;
      }
    }
  }
}

.mr-0 {
  margin-right: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.MuiButtonBase-root.MuiCheckbox-root {
  padding: 9px;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.visiblity-hidden {
  visibility: hidden;
}

.pac-item {
  cursor: pointer;
}

.card-type-img-in-card-no {
  height: 32px;
  width: 50px;
}


.hide {
  display: none;
}




/**
* Menu item code
**/
@media (max-width: 990px) {

  .mobile-menu-parent {
    max-height: calc(100vh - 114px);
    overflow: auto;

    li {
      width: 100%;
    }
  }

  .mobile-menu-parent li .dropdown {
    position: unset;
    box-shadow: none;
  }

  //  .mobile-menu-parent .arrow{
  //   display: block!important;
  // }
  .mobile-menu-parent .dropdown .dropdown-submenu {
    position: unset !important;
  }
}

.mobile-menu-parent li img.arrow-main {
  display: none;

  @media (max-width: 990px) {
    display: block;
    transform: rotate(90deg);
    width: 20px;
    margin-left: auto;
  }
}

.mobile-menu-parent li img.arrow-child {
  margin-left: auto;
  width: 18px;
}

.mobile-menu-parent .nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.mobile-menu-parent .logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

.mobile-menu-parent .menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.mobile-menu-parent .menu-items {
  position: relative;
  font-size: 14px;
}

.mobile-menu-parent .menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.mobile-menu-parent .menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.mobile-menu-parent button span {
  margin-left: 3px;
}

.mobile-menu-parent .menu-items>a,
.mobile-menu-parent .menu-items button {
  text-align: left;
  padding: .7rem 0.5rem;
}

.mobile-menu-parent .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.mobile-menu-parent .dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 11rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0px;
  display: none;
}

.mobile-menu-parent .dropdown.show {
  display: block;
}

.mobile-menu-parent .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}


* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:focus {
  outline: none !important;
}

.block.before-click::before {
  content: '';
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
}

// #gift-html-image .opacity-0{
//  // display: none!important;
//  opacity: 0!important;
// }

#gift-html-image {
  position: absolute;
  z-index: -1;
  left: -10000px;
  top: auto;
}

// #gift-html-image .fixed{
//   position: static;
// }

.discription-area {
  & * {
    cursor: default !important;
  }

  p,
  li {
    margin-bottom: 4px;

    strong {
      margin-top: 12px;
    }
  }
}

.break-words {
  word-break: break-word !important;
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

.MuiFormControl-root.error-fixed {
  position: relative;

  &+.text-red-400 {
    position: absolute;
    bottom: -16px;
    left: 0px;
    white-space: nowrap;
  }
}