
 @font-face { 
    font-display: swap; 
    font-style : normal; 
    src: url("assets/fonts/Nunito-SemiBold.ttf"); 
    font-family : "Nunito-SemiBold"; 
    font-weight : 500;  
}
 @font-face { 
    font-display: swap; 
    font-style : normal; 
    src: url("assets/fonts/Nunito-Medium.ttf"); 
    font-family : "Nunito-Medium"; 
    font-weight : 400;  
}
 @font-face { 
    font-display: swap; 
    font-style : normal; 
    src: url("assets/fonts/Nunito-Bold.ttf"); 
    font-family : "Nunito-Bold"; 
    font-weight : 400;  
}
 @font-face { 
    font-display: swap; 
    font-style : normal; 
    src: url("assets/fonts/Nunito-Black.ttf"); 
    font-family : "Nunito-Black"; 
    font-weight : 700;  
}